export const feedCategories = [{
    key: "All",
    value: ""
}, {
    key: "Goli Khal",
    value: "Goli Khal",
}, {
    key: "Silage",
    value: "Silage"
}, {
    key: "Khal",
    value: "Khal"
}]

export const bhainsCategories = [{
    key: 'All',
    value: ""
}, {
    key: 'Gurgaon',
    value: 'Gurgaon'
}, {
    key: 'Jind',
    value: 'Jind'
}, {
    key: 'Matan',
    value: 'Matan'
}]

export const ROUTE_ROLE_PERMISSION = {
    // Salesperson:[],
    Salesperson:["SalesPerson","CSAdmin"],
    // - correct one 
    // Salesperson: ["Admin", "HubManager", "Vet", "DataAdmin", "QChead", "ProcurementManager", "ProcurementHead", "CS", "DevTeam", "Farmer"],
}

export const SideBarMenuLinks=[{
    icon:"orderTrackerIcon",
    text:"Order Tracker",
    url:"/searchorder"
},{
    icon:"customerListIcon",
    text:"Customer List",
    url:"/searchcustomer"
},{
    icon:"orderHistoryIcon",
    text:"View Order History",
    url:"/salesperson/orderlisting"
},
{
    icon:"orderInsightsIcon",
    text:"Your Order Insights",
    url:"/salesperson/insights"
},
{
    icon:"customersIcon",
    text:"Your Customer",
    url:"/salesperson/yourcustomers"
}
]

export const addCustomerSubmitRequiredKey=['phone', 'firstLine', 'fathersName', 'city', 'district', 'pin', 'state','country', 'landMark','businessType']
export const editAddressRequiredKey=[ 'firstLine', 'landMark', 'city', 'pin', 'state', 'country']
export const billPaymentMethods:any = {
    "COD" : 'Cash on Delivery','PAYMENT_LINK':'Payment Link'
}