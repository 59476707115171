import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertOrderToCOD, getCheckoutSummary, getOrderSummary } from "../reduxThunk/orderSummary";
import { Order } from "../../entities/Order";

interface OrderState {
  loading: boolean;
  error: string;
  data?: Order | null;
}

const initialState: OrderState = {
  loading: false,
  error: "",
  data: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateOrderDetails: (state, action: PayloadAction<OrderState>) => {},
  },

  extraReducers: (builder) => {
    builder

      .addCase(getOrderSummary.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getOrderSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.data = Order.fromBffResponse( action.payload.data.OrderDetails)
      })
      .addCase(getOrderSummary.rejected, (state) => {
        state.loading = false;
        state.error = "No details";
      })
      .addCase(getCheckoutSummary.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCheckoutSummary.fulfilled, (state, action) => {
        console.log("fulfilled");
        
        state.loading = false;
        state.error = ""; 
        console.log(action.payload);
        
        state.data = Order.fromBffResponse( action.payload.data.CheckoutDetails)
      })
      .addCase(getCheckoutSummary.rejected, (state) => {
        state.loading = false;
        state.error = "No details";
      })
      .addCase(convertOrderToCOD.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(convertOrderToCOD.fulfilled, (state, action) => {
        console.log("Fulfilled Action Payload:", action.payload);
        state.loading = false;
        state.error = "";
        state.data = { ...state.data, ...action.payload.ConvertOrderToCOD };
      })

      .addCase(convertOrderToCOD.rejected, (state, action) => {
        state.loading = false;
        state.error = "Selected payment method is not payment link";
      });
  },
});

export const { updateOrderDetails } = orderSlice.actions;
export default orderSlice.reducer;
