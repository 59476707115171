import React, { useEffect } from "react";


const ChatBot = () => {
  const dfMessengerStyles = `
    df-messenger {
      z-index: 999;
      position: fixed;
      --df-messenger-font-color: #000;
      --df-messenger-font-family: Google Sans;
      --df-messenger-chat-background: #f3f6fc;
      --df-messenger-message-user-background: #d3e3fd;
      --df-messenger-message-bot-background: #fff;
      bottom: 0;
      right: 0;
      top: 0;
      width: 350px;
    }
  `;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
        console.log("script loaded.");
      };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
    <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
        Main Hoon 360
      </h1>
    <style>{dfMessengerStyles}</style>

    <df-messenger
    location="asia-south1"
    project-id="hubemployee-189ad"
    agent-id="6fe363c5-ec80-4951-b661-5208aaa208d6"
    language-code="en"
    max-query-length="-1"
    allow-feedback="all"
    >
    <df-messenger-chat chat-title="MP360 Internal"></df-messenger-chat>
    </df-messenger>

    </div>
  );
};

export default ChatBot;
